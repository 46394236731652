@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hiden scroll bar  */
.hidden-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-input {
    padding: 10px 11px;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-input-search .ant-input-search-button {
    height: 2.75rem;
    background-color: #1677ff;
}

.ant-btn-primary {
    background-color: #1677ff;
}

.ck-editor {
    height: 350px !important;
    /* border-radius: 10px !important; */
}
.ck-editor__main {
    height: 300px !important;
}
.ck-content {
    height: 300px !important;
    border-end-end-radius: 10px !important;
    border-end-start-radius: 10px !important;
}

/* .ck{
    border-radius: 10px !important;
} */

.ck-sticky-panel {
    border-radius: 10px !important;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-btn-primary {
    color: black !important;
    border: 0.5px solid gray !important;
}

/* css input antd */
.ant-input-group {
    height: 100% !important;
}
.ant-input-group .ant-input {
    height: 100% !important;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    height: 100% !important;
}
.ck-content h1 {
    font-size: 32px;
}
.ck-content h2 {
    font-size: 24px;
}
.ck-content h3 {
    font-size: 20px;
}
.ck-content h4 {
    font-size: 18px;
}
.ck-content h5 {
    font-size: 16px;
}
.ck-content h6 {
    font-size: 14px;
}
.ck-content h7 {
    font-size: 12px;
}
.ck-content p {
    font-size: 15px;
}
.ant-select-disabled,
.ant-select-selector {
    background-color: #fff !important;
    color: #000 !important;
}
